@mixin mobile() {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin tablet() {
  @media screen and (max-width: 1023px) {
    @content;
  }
}
@mixin laptop() {
  @media screen and (max-width: 1469px) {
    @content;
  }
}

@mixin container {
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
  @include laptop() {
    max-width: 47rem;
  }
  @include tablet() {
    max-width: 35rem;
    border-right: unset;
  }
  @include mobile() {
    max-width: 20rem;
  }
}