@import "../../assets/scss/importer";

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $gray;
  padding: $padding;
}

.content {
  text-align: center;
  color: $white;
}

.logo {
  width: 100%;
  max-width: 30rem;
  @include tablet() {
    max-width: 22rem;
  }
  @include mobile() {
    max-width: 18rem;
  }
}
