@import "../../assets/scss/importer";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $gray;
}

.container {
  @include container();
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
  @include mobile() {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  a {
    color: $white;
    text-decoration: unset;
    cursor: pointer;
  }
}
