@import "../../assets/scss/importer";

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: $gray;
  padding: $padding;
}

.content {
  text-align: center;
  color: $white;
}

.logo {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 30rem;
  @include tablet() {
    max-width: 22rem;
  }
  @include mobile() {
    max-width: 18rem;
  }
}

.contacts {
  text-align: center;
  margin-top: 1rem;
  p {
    a {
      color: rgba($white, 0.3);
      text-decoration: unset;
    }
  }
}


.footerNotes {
  text-align: center;
  small {
    color: #575454!important;
    font-size: 80%;
  }
}