@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap");
@import "importer";

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 20px;
  * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    font-family: "PT Sans Narrow", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    color: $black;
  }
}

html,
body,
#root,
.App {
  height: 100%;
  background: $white;
}

body {
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: 700;
}

p {
  font-size: 1.3rem;
  line-height: 1.6rem;
  @include mobile() {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  margin: 0;
  * {
    font-size: 1.3rem;
    line-height: 1.6rem;
    @include mobile() {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }
  }
}
