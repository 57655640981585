@import "../../assets/scss/importer";

.wrapper {
  padding: 0 1rem;
  @include tablet() {
    position: relative;
  }
}

.container {
  min-height: 100vh;
  // padding: $padding;
  padding: 2rem 0;
  border-right: 2px solid rgba($gray, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  @include container();
  @include tablet() {
    border-right: unset;
    min-height: unset;
  }
}

.content {
  display: flex;
  flex-direction: row;
}

.leftColumn {
  width: 27.5rem;
  @include laptop() {
    max-width: 16rem;
  }
  @include tablet() {
    position: absolute;
    bottom: 1rem;
    left: 0;
    z-index: -1;
    opacity: 0.25;
  }
  p {
    font-size: 20rem;
    line-height: 28rem;
    position: relative;
    top: -2.5rem;
    background: linear-gradient(#e33434, #904292);
    background: -webkit-linear-gradient(#e33434, #904292);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include laptop() {
      font-size: 16rem;
      line-height: 22rem;
    }
    @include tablet() {
      font-size: 16rem;
      line-height: 22rem;
      top: 0;
    }
  }
}

.rightColumn {
  flex: 1;
  background-color: $white;
  padding: 0 1rem;
  position: relative;
  right: -2px;
  align-self: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include tablet{
    padding: 0;
    background-color: transparent;
  }
}
